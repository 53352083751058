<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <signer-info :signer="signer"/>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 border">
                <div class="section-header">
                  <button class="btn btn-primary mt-1" type="button" @click="signContract" v-if="hasAction('SIGN_SIGNING') && isSignContract">
                    Shartnomani imzolash
                  </button>
                  <loading v-else-if="loadingSignContract"/>
                  <b class="text-danger" v-if="signDataForContract.signedDocument.espStatus === 'DONE'">Shartnoma allaqachon tasdiqlangan</b>
                </div>
                <div class="row">
                  <div class="col-12 text-left">
                    <label><b>Imzolash uchun tekst</b></label>
                    <div class="mt-1 mb-1 border p-2">{{ signDataForContract.signedDocument.signText }}</div>
                  </div>
                </div>
                <div class="row mt-2" v-if="signDataForContract.signedDocument.espStatus !== 'DONE'">
                  <div class="col-12" :class="!loadingContract ? 'frameHeight' : ''">
                    <loading v-if="loadingContract"></loading>
                    <iframe v-show="!loadingContract" id="reportFrameContract"></iframe>
                  </div>
                </div>
              </div>
              <div class="col-6 border">
                <div class="section-header">
                  <button class="btn btn-primary mt-1" type="button" @click="signReport" v-if="hasAction('SIGN_SIGNING') && isSignReport">
                    Hisobotni imzolash
                  </button>
                  <loading v-else-if="loadingSignReport"/>
                  <b class="text-danger" v-if="signDataForContract.signedDocument.espStatus !== 'DONE' && signDataForReport.signedDocument.espStatus !== 'DONE'">Oldin Shartnomani imzolash kerak!</b>
                  <b class="text-danger" v-if="signDataForReport.signedDocument.espStatus === 'DONE'">Hisobot allaqachon tasdiqlangan</b>
                </div>
                <div class="row">
                  <div class="col-12 text-left">
                    <label><b>Imzolash uchun tekst</b></label>
                    <div class="mt-1 mb-1 border p-2">{{ signDataForReport.signedDocument.signText }}</div>
                  </div>
                </div>
                <div class="row mt-2" v-if="signDataForReport.signedDocument.espStatus !== 'DONE'">
                  <div class="col-12" :class="!loadingReport ? 'frameHeight' : ''">
                    <loading v-if="loadingReport"></loading>
                    <iframe v-show="!loadingReport" id="reportFrame"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/common/Loading"
import SignerInfo from "./signer-info"
import EIMZOClient from '../../shared/sign/vue-e-imzo-client'
import SIGN from "../../shared/sign/sign-document"

export default {
  name: "contract-report",
  props: {
    contractData: Object,
    reportData: Object
  },
  components: {
    Loading,
    SignerInfo
  },
  data() {
    return {
      signer: null,
      loadingContract: false,
      loadingReport: false,
      loadingSignContract: false,
      loadingSignReport: false,
      signDataForContract: {
        uuid: null,
        pkcs7: null,
        keyId: null,
        signedDocument: {
          signText: '',
          espStatus: 'PENDING'
        },
        signRequest: {
          data: {
            TIN: null,
            PINFL: null
          }
        },
        isSigned: false
      },
      signDataForReport: {
        uuid: null,
        pkcs7: null,
        keyId: null,
        signedDocument: {
          signText: ''
        },
        signRequest: {
          data: {
            TIN: null,
            PINFL: null
          }
        },
        isSigned: false
      }
    }
  },
  computed: {
    isKeyIdRequiredReport() {
      return this.signDataForReport.keyId
    },
    isPkcs7RequiredReport() {
      return this.signDataForReport.pkcs7
    },
    isKeyIdRequiredContract() {
      return this.signDataForContract.keyId
    },
    isPkcs7RequiredContract() {
      return this.signDataForContract.pkcs7
    },
    isSignReport() {
      return !this.loadingSignReport && this.signer && !this.signer.vo.expired && !this.signDataForReport.isSigned && this.signDataForContract.signedDocument.espStatus === 'DONE' &&
          this.signDataForReport.signedDocument.espStatus === 'PENDING' && this.signDataForReport.signedDocument.signText && this.signDataForReport.signedDocument.signText !== ''
    },
    isSignContract() {
      return !this.loadingSignContract && this.signer && !this.signer.vo.expired && !this.signDataForContract.isSigned &&
          this.signDataForContract.signedDocument.espStatus === 'PENDING' && this.signDataForContract.signedDocument.signText && this.signDataForContract.signedDocument.signText !== ''
    }
  },
  methods: {
    init() {
      if (this.signDataForContract.signedDocument.espStatus !== 'DONE') {
        this.loadingContract = true
        this.$http.post('/sign/contract/file/' + this.signDataForContract.signRequest.appName + "/" + this.signDataForContract.signRequest.instance.name, this.signDataForContract.signedDocument)
            .then(response => {
              let blob = new Blob([response.data], {type: 'application/pdf'})
              let encodedString = URL.createObjectURL(blob)
              let iFrame = document.getElementById("reportFrameContract")
              iFrame.src = encodedString

              this.loadingContract = false
            })
            .catch(err => {
              console.log(err)
              this.loadingContract = false
            })
      }

      if (this.signDataForReport.signedDocument.espStatus !== 'DONE') {
        this.loadingReport = true
        this.$http.get(this.signDataForReport.signedDocument.fileUrl, {responseType: 'arraybuffer'})
            .then(response => {
              let blob = new Blob([response.data], {type: 'application/pdf'})
              let encodedString = URL.createObjectURL(blob)
              let iFrame = document.getElementById("reportFrame")
              iFrame.src = encodedString

              this.loadingReport = false
            })
            .catch(err => {
              console.log(err)
              this.loadingReport = false
            })
      }
    },
    signContract() {
      const vm = this;
      this.loadingSignContract = true
      if (!vm.isKeyIdRequiredContract){
        EIMZOClient.loadKey(vm.signer.vo, function (id) {
          vm.signDataForContract.keyId = id;
          EIMZOClient.createPkcs7(id, vm.signDataForContract.signedDocument.signText, null, function (pkcs7) {
            vm.signDataForContract.pkcs7 = pkcs7;
            vm.sign(vm.signDataForContract, vm.isPkcs7RequiredContract)
          }, function (e, r) {
            SIGN.errorMessage(r, e)
            vm.loadingSignContract = false
          })
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSignContract = false
        })
      } else {
        EIMZOClient.createPkcs7(vm.signDataForContract.keyId, vm.signDataForContract.signedDocument.signText, null, function (pkcs7) {
          vm.signDataForContract.pkcs7 = pkcs7;
          vm.sign(vm.signDataForContract, vm.isPkcs7RequiredContract)
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSignContract = false
        })
      }
    },
    signReport() {
      const vm = this;
      this.loadingSignReport = true
      if (!vm.isKeyIdRequiredReport){
        EIMZOClient.loadKey(vm.signer.vo, function (id) {
          vm.signDataForReport.keyId = id
          EIMZOClient.createPkcs7(id, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
            vm.signDataForReport.pkcs7 = pkcs7
            vm.sign(vm.signDataForReport, vm.isPkcs7RequiredReport)
          }, function (e, r) {
            SIGN.errorMessage(r, e)
            vm.loadingSignReport = false
          })
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSignReport = false
        })
      } else {
        EIMZOClient.createPkcs7(vm.signDataForReport.keyId, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
          vm.signDataForReport.pkcs7 = pkcs7
          vm.sign(vm.signDataForReport, vm.isPkcs7RequiredReport)
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSignReport = false
        })
      }
    },
    sign(data, valid) {
      if (!valid){
        this.$swal({title: 'Diqqat!', text: 'Imzolashda xatolik. pkcs7 topilmadi!', type: 'warning'});
        return
      }

      this.$http.post('/sign', data)
          .then(res => {
            if (res.data.status === 'BAD_REQUEST') {
              this.$swal({title: 'fail', text: res.data.message, type: 'warning'})
            } else {
              this.$swal({title: 'success', text: res.data.message, type: 'success'})
              this.signDataForContract.signedDocument.espStatus = 'DONE'
            }
            data.isSigned = true
            this.loadingSignContract = false
            this.loadingSignReport = false
          }, err => {
            this.loadingSignContract = false
            this.loadingSignReport = false
            console.log(err)
          })
    }
  },
  created() {
    this.signDataForContract.signRequest = this.$props.contractData.signRequest
    this.signDataForContract.signedDocument = this.$props.contractData.signedDocument

    this.signDataForReport.signRequest = this.$props.reportData.signRequest
    this.signDataForReport.signedDocument = this.$props.reportData.signedDocument

    if (!this.signDataForContract.signRequest.data.PINFL){
      this.$swal({title: 'Diqqat!', text: 'PINFL topilmadi!', type: 'warning'})
      return
    }

    if (!this.signDataForReport.signRequest.data.PINFL){
      this.$swal({title: 'Diqqat!', text: 'PINFL topilmadi!', type: 'warning'})
      return
    }

    SIGN.signInit()

    setTimeout(() => {
      this.signer = SIGN.singer('ENTITY', this.signDataForReport.signRequest.data.TIN, this.signDataForReport.signRequest.data.PINFL)
    }, 1000)

    this.init()
  }
}
</script>

<style scoped>
#reportFrame, #reportFrameContract {
  width: 100%;
  height: 100%;
}

.frameHeight {
  height: 700px;
}

textarea {
  border: 1px solid #2b8dc6 !important;
  border-radius: 10px;
}
</style>