<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header ml-4">
            <signer-info :signer="signer"/>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="section-header">
                  <button class="btn btn-primary" type="button" @click="signReport" v-if="hasAction('SIGN_SIGNING') && isSign">
                    Hisobotni imzolash
                  </button>
                  <loading v-else-if="loadingSign"/>
                  <b class="text-danger" v-if="signDataForReport.signedDocument.espStatus === 'DONE'">Hisobot allaqachon tasdiqlangan</b>
                </div>
                <div class="row pr-5">
                  <div class="col-12 text-left ml-4">
                    <label><b>Imzolash uchun tekst</b></label>
                    <div class="mt-3 mb-3 border p-2">{{signDataForReport.signedDocument.signText}}</div>
                  </div>
                </div>
                <div class="row mt-5" v-if="signDataForReport.signedDocument.espStatus !== 'DONE'">
                  <div class="col-12" :class="!loading ? 'frameHeight' : ''">
                    <loading v-if="loading"></loading>
                    <iframe v-show="!loading" id="reportFrame"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/common/Loading"
import SignerInfo from "./signer-info"
import EIMZOClient from '@/shared/sign/vue-e-imzo-client'
import SIGN from "../../shared/sign/sign-document"

export default {
  name: "report",
  props: {
    reportData: Object,
    signerType: String
  },
  components: {
    SignerInfo,
    Loading
  },
  data() {
    return {
      loading: false,
      loadingSign: false,
      signer: null,
      signDataForReport: {
        uuid: null,
        pkcs7: null,
        keyId: null,
        signedDocument: {
          signText: '',
          espStatus: 'PENDING'
        },
        signRequest: {
          data: {
            TIN: null,
            PINFL: null
          }
        },
        isSigned: false
      }
    }
  },
  computed: {
    isKeyIdRequired() {
      return this.signDataForReport.keyId
    },
    isPkcs7Required() {
      return this.signDataForReport.pkcs7
    },
    isSign() {
      return !this.loadingSign && this.signer && !this.signer.vo.expired && !this.signDataForReport.isSigned &&
          this.signDataForReport.signedDocument.espStatus === 'PENDING' && this.signDataForReport.signedDocument.signText && this.signDataForReport.signedDocument.signText !== ''
    }
  },
  methods: {
    init() {
      if (this.signDataForReport.signedDocument.espStatus !== 'DONE') {
        this.loading = true
        this.$http.get(this.$props.reportData.signedDocument.fileUrl, {responseType: 'arraybuffer'})
            .then(response => {
              let blob = new Blob([response.data], {type: 'application/pdf'})
              let encodedString = URL.createObjectURL(blob)
              let iFrame = document.getElementById("reportFrame")
              iFrame.src = encodedString

              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
      }
    },
    sign() {
      if (!this.isPkcs7Required){
        this.$swal({title: 'Diqqat!', text: 'Imzolashda xatolik. pkcs7 topilmadi!', type: 'warning'})
        return
      }

      this.$http.post('/sign', this.signDataForReport)
          .then(res => {
            if (res.data.status === 'BAD_REQUEST') {
              this.$swal({title: 'fail', text: res.data.message, type: 'warning'})
            } else {
              this.$swal({title: 'success', text: res.data.message, type: 'success'})
            }
            this.signDataForReport.isSigned = true
            this.loadingSign = false
          }, err => {
            this.loadingSign = false
            console.log(err)
          })
    },
    signReport() {
      const vm = this;
      this.loadingSign = true
      if (!vm.isKeyIdRequired){
        EIMZOClient.loadKey(vm.signer.vo, function (id) {
          vm.signDataForReport.keyId = id
          EIMZOClient.createPkcs7(id, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
            vm.signDataForReport.pkcs7 = pkcs7
            vm.sign()
          }, function (e, r) {
            SIGN.errorMessage(r, e)
            vm.loadingSign = false
          })
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSign = false
        })
      } else {
        EIMZOClient.createPkcs7(vm.signDataForReport.keyId, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
          vm.signDataForReport.pkcs7 = pkcs7
          vm.sign()
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSign = false
        })
      }
    }
  },
  created() {
    this.signDataForReport.signRequest = this.$props.reportData.signRequest
    this.signDataForReport.signedDocument = this.$props.reportData.signedDocument

    if (!this.signDataForReport.signRequest.data.PINFL){
      this.$swal({title: 'Diqqat!', text: 'PINFL topilmadi!', type: 'warning'})
      return
    }

    SIGN.signInit()

    if (this.$props.signerType === 'ENTITY') {
      setTimeout(() => {
        this.signer = SIGN.singer('ENTITY', this.signDataForReport.signRequest.data.TIN, this.signDataForReport.signRequest.data.PINFL)
      }, 1000)
    } else {
      setTimeout(() => {
        this.signer = SIGN.singer('INDIVIDUAL', this.signDataForReport.signRequest.data.TIN, this.signDataForReport.signRequest.data.PINFL)
      }, 1000)
    }

    this.init()
  }
}
</script>

<style scoped>
#reportFrame, #reportFrameContract {
  width: 97%;
  height: 100%;
}

.frameHeight {
  height: 700px;
}

textarea {
  border: 1px solid #2b8dc6 !important;
  border-radius: 10px;
}
</style>