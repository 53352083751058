<template>
  <div>
    <contract-report v-if="contractData && reportData" :contractData="contractData" :reportData="reportData"/>
    <report v-else-if="reportData" :reportData="reportData" :signerType="signerType"/>
    <registration v-if="userData" :userData="userData"/>
  </div>
</template>

<script>
import ContractReport from "../../components/sign/contract-report"
import Report from "../../components/sign/report"
import Registration from "../../components/sign/registration"
export default {
  name: "sign",
  components: {
    ContractReport,
    Report,
    Registration
  },
  data() {
    return {
      reportData: null,
      contractData: null,
      userData: null,
      signerType: 'INDIVIDUAL'
    }
  },
  methods: {
    init(resData) {
      if (resData.signDocuments && resData.signDocuments.length > 0){
        for (let i = 0; i < resData.signDocuments.length; i++){
          if (resData.signDocuments[i].espType  === 'DIRECTOR_SIGN_ACT_INVOICE'){
            this.contractData = {
              signRequest : resData,
              signedDocument: resData.signDocuments[i]
            }
          } else if (resData.signDocuments[i].espType  === 'USER_SIGN_REGISTRATION' || resData.signDocuments[i].espType  === 'DIRECTOR_SIGN_REGISTERED_USER'){
            this.userData = {
              signRequest : resData,
              signedDocument: resData.signDocuments[i]
            }
          } else {
            if (resData.signDocuments[i].espType  === 'DIRECTOR_REPORT'){
              this.signerType = 'ENTITY'
            }
            this.reportData = {
              signRequest : resData,
              signedDocument: resData.signDocuments[i]
            }
          }
        }
      }
    }
  },
  created() {
    this.$http.get('sign/url-param-decode/' + this.$route.params.code).then(res => {
      this.init(res.data)
    }, err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>